<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->



  <div id="q-app" style="min-height: 100vh;">
    <checklist-page
                    @closeModal="closeChecklistPage()"
                    @reloadForm="getData()"
                    v-if="this.selected_form"
                    :task="returned_task"
                    :key="selected_form"
                    :show-modal="this.checklist_busy"
                    v-model="checklist_busy"
                    :form="this.selected_form">

    </checklist-page>
    <div class="q-pa-md" style="max-width: 100%">
      <div>
        <q-card class="my-card bg-gray text-gray">
          <q-card-section>
            <q-item v-ripple>
              <q-item-section class="text-h5">{{this.returned_task.reference}} </q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings">

                </q-icon>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section v-if="this.returned_task.task_status">
                Status : {{this.returned_task.task_status.description}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                Task title: {{this.returned_task.title}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                Task address: {{this.returned_task.address}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section avatar>
                Due Date: {{this.returned_task.due_date}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section >
                Description: {{this.returned_task.description}}
              </q-item-section>
            </q-item>
          </q-card-section>

        </q-card>
        <q-card class="bg-white text-white" style="width: 100%">
          <q-separator dark></q-separator>
          <q-list  bordered>
            <q-item>
              <q-item-section v-if="!userAccepted">
                  <q-btn @click="acceptTask()"  color="secondary" icon="check" label="Accept Task"/>
              </q-item-section>
              <div  v-if="this.returned_task.task_status.description !== 'Completed'">
                <q-item-section v-for="(form,index) in this.returned_forms"
                                :key="index">
                  <q-item-label class="bg-white text-black q-mt-md q-mb-md">
                    {{ this.calculateFilled(form) }}  / {{form.form_items.length}} items in {{form.description}}
                  </q-item-label>
                  <q-btn @click="selectChecklist(form)"
                         v-if="this.isComplete(form)"
                         color="secondary" icon="check"
                         :label="form.description+' Complete'"/>
                  <q-btn @click="selectChecklist(form)"
                         v-if="!this.isComplete(form)"
                         color="primary" icon="rule"
                         :label="form.description"/>
                </q-item-section>
              </div>
            </q-item>
            <q-item>
              <q-item-section>
                <div v-if="allFormsComplete &&
                  this.returned_task.task_status &&
                  this.userAccepted &&
                  this.returned_task.task_status.description !== 'Completed'"
                >
                  <q-btn @click="completeTask()"  color="secondary" icon="check" label="Complete Task"/>
                </div>
                <q-item-label
                    v-if="this.returned_task.task_status &&
                    this.returned_task.task_status.description === 'Completed'"
                    class="bg-white text-black q-mt-md q-mb-md">
                  Task Completed
                </q-item-label>

              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRoute } from 'vue-router'
import {useQuasar} from "quasar";
import { Notify } from 'quasar';
import { Geolocation } from '@capacitor/geolocation'
import ChecklistPage from "@/components/ChecklistPage.vue";
export default {
  name: 'ExecuteTasksPage',
  components: {
    ChecklistPage,
  },
  computed:{
    allFormsComplete(){
      return this.checkAllFormsComplete();
    },
    token(){
      return this.$store.getters.token;
    },
    user_id(){
      return this.$store.getters.user_id;
    },
    url(){
      return this.$store.getters.url;
    },
    userAccepted(){
      if(this.returned_task && this.returned_task.assigned_to_user){
        if(parseInt(this.returned_task.assigned_to_user.accepted) === 1){
            return true;
          }else{
            return false;
          }
      }else {
        return false;
      }
    }
  },
  setup(){
    return {

    }
  },
  data(){
    return {
      checklist_busy : false,
      returned_task : Object,
      returned_forms : [],
      parameter_id : null,
      selected_form : null,
      multiple : [],
      options : [],
      clockUsers : [],
      forms : Object,
      longitude : null,
      latitude : null
    }
  },
  mounted(){
     this.setupParameters();
     this.getData();
     this.getUsers();
     this.getLocation();
  },
  methods : {
    closeChecklistPage(){
      this.checklist_busy = false;
      this.selected_form = null;
      window.location.reload();
    },
    isComplete(form){
       let filled = this.calculateFilled(form);
      if(filled === form.form_items.length){
        return true;
      }else{
        return false;
      }
    },
    checkAllFormsComplete(){
      console.log(this.returned_forms);
      let result = true;
      this.returned_forms.forEach((form) => {
        console.log(this.isComplete(form));
        if(!this.isComplete(form)){
          result = false;
        }
      });
      return result;
    },
    calculateFilled(form){
      let filled = 0;
      form.form_items.forEach((item) => {
        if(item.form_answers){
          item.form_answers.forEach((answer) => {
            if(answer.answers){
              filled++;
            }
          });
        }
      });
      return filled;
    },
    selectChecklist(form){
      this.selected_form = null;
      this.selected_form = form;
      this.checklist_busy = true;
    },
    acceptTask(){
      let formData = new FormData();
      formData.append('user_id', this.user_id);
      formData.append('task_id', this.returned_task.id);
      let url = this.url + '/api/api-task-accept-participation';
      axios.post(url , formData, {
        headers: {
          Authorization: 'Bearer ' + this.token,
          "Content-Type": "multipart/form-data",
        }
      }).then(response => {
        if(response.data.success) {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Task Accepted'
          })
          this.returned_task.assigned_to_user.accepted = 1;
        }else{
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'Task Acceptance Failed'
          })
        }
      }).catch(error => {
        console.error('There was an error!', error.response);
      });
    },
    completeTask(){
      axios.post(this.$store.getters.url+'/api/api-task-complete',{
        task_id : this.returned_task.id,
        user_id : this.user_id,
      },{
        headers:{
          Authorization : 'Bearer '+this.token
        }
      })
          .then((res) => {
            console.log(res);
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Task Completed'
            })
          })
          .catch((error) => {
            console.log(error);
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Task Completion Failed'
            })
          }).finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users',{
        headers:{
          Authorization : 'Bearer '+this.token
        }
      })
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    getLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      }
    },
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
    },
    async getData() {
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-tasks/' + this.parameter_id,{
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      })
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_task = res.data.task;
            let forms = res.data.forms;
            forms.forEach((form) => {
              if(form.type==='checklist'){
                this.returned_forms.push (form);
              }
            });
            console.log(this.returned_task);
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: ' + error
            })
          }).finally(() => {
        this.forceRefresh();
        //Perform action in always
      });
    },
    forceRefresh(){
      this.$forceUpdate();  // Notice we have to use a $ here
    },
  }
}
</script>
