<template>
  <q-dialog persistent :full-width="true" v-model="modal_shown">
    <q-page-container>
      <q-page class="q-pa-md">
          <q-card class="dialog-card my-card bg-gray text-gray">
            <q-card-section>
              <q-item v-ripple>
                <q-item-section class="text-h5">{{this.task.reference}} </q-item-section>
                <q-item-section avatar>
                  <q-icon color="primary" name="settings">

                  </q-icon>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <p class="text-h6">{{ the_form.type }} : {{ the_form.description}}</p>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-btn @click="this.currentPage = 1" :class="currentPage === 1 ? 'bg-blue text-white' : 'bg-grey text-black'">Not Filled</q-btn>
                  <q-btn @click="this.currentPage = 2" :class="currentPage === 2 ? 'bg-blue text-white' : 'bg-grey text-black'">Submitted</q-btn>
                </q-item-section>
              </q-item>

              <div v-if="currentPage === 1">
                <div v-for="form_item in the_form.form_items" :key="form_item.id">
                  <div v-for="item in form_item.form_answers" :key="item.id">
                    <div v-if="item.submitted === 0">
                      <q-item>
                        <q-item-section>
                          <q-item-label class="q-mt-md q-mb-md">
                            {{ form_item.description }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <q-item-label class="q-mt-md q-mb-md">
                            {{ form_item.label }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <div v-if="form_item.type==='Datetime'" class="flex py-4 px-4">
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='date'" type="date"/>
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='datetime'" type="datetime-local"/>
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='time'" type="time"/>
                          </div>
                          <div v-if="form_item.type==='Text'" class="flex  py-4 px-4">
                                        <textarea
                                            v-model="item.answers"
                                            :rows="JSON.parse(form_item.options).lines"
                                            :minlength="JSON.parse(form_item.options).minLength"
                                            :maxlength="JSON.parse(form_item.options).maxLength"
                                        />
                          </div>
                          <div v-if="form_item.type==='Signature'" class="flex py-4 px-4">
                            <input  type="hidden" v-model="item.answers"/>
                            <SignatureCard :model-value="item.file"
                                           @update:extension="extension => handleExtension(extension, form_item)"
                                           @update:files="files => handleFiles(files, form_item)">
                            </SignatureCard>
                          </div>
                          <div v-if="form_item.type==='Number'" class="flex  py-4 px-4">
                            <input
                                v-model="item.answers" type="number"
                                :min="JSON.parse(form_item.options).Minimum"
                                :max="JSON.parse(form_item.options).Maximum"
                            />
                          </div>
                          <div v-if="form_item.type==='Choices'" class="flex  py-4 px-4">
                            <select   v-model="item.answers" :multiple="JSON.parse(form_item.options).multiChoice" class="rounded-lg mt-2 mb-2">
                              <option selected>
                                -- Select --
                              </option>
                              <option v-for="choice in JSON.parse(form_item.options).selectable_options" :key="choice" :value="choice">
                                {{ choice }}
                              </option>
                            </select>
                          </div>
                          <div v-if="form_item.type==='Boolean'" class="flex py-4 px-4">
                            <select  v-model="item.answers" class="rounded-lg mt-2 mb-2">
                              <option selected>
                                -- Select --
                              </option>
                              <option :value="JSON.parse(form_item.options).falseOption">
                                {{ JSON.parse(form_item.options).falseOption }}
                              </option>
                              <option :value="JSON.parse(form_item.options).trueOption">
                                {{ JSON.parse(form_item.options).trueOption }}
                              </option>
                            </select>
                          </div>
                          <div class="q-mt-md q-mb-md" v-if="form_item.type==='Boolean' && JSON.parse(form_item.options).negativeActionValues.hasPhotos">
                            <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                              <DropzoneComponent
                                  @update:extension="extension => handleExtension(extension, form_item)"
                                  @update:files="files => handleFiles(files, form_item)"
                                  :type="(form_item.options).fileType">
                              </DropzoneComponent>
                            </div>
                            <div class="w-1/3" v-if="form_item.file">
                              <img
                                  :src="createBlob(form_item)"
                                  style="height: 140px; max-width: 150px"
                              >
                            </div>
                          </div>
                          <div v-if="form_item.type==='File'" class="flex  py-4 px-4">
                            <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                              <DropzoneComponent
                                  @update:extension="extension => handleExtension(extension, form_item)"
                                  @update:files="files => handleFiles(files, form_item)"
                                  :type="(form_item.options).fileType">
                              </DropzoneComponent>
                            </div>
                            <div class="w-1/3" v-if="form_item.file">
                              <img
                                  :src="createBlob(form_item)"
                                  style="height: 140px; max-width: 150px"
                              >
                            </div>
                          </div>
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-btn @click="storeAnswer(form_item)">Submit</q-btn>
                      </q-item>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentPage === 2">
                <div v-for="form_item in the_form.form_items" :key="form_item.id">
                  <div v-for="item in form_item.form_answers" :key="item.id">
                    <div v-if="item.submitted === 1">
                      <q-item>
                        <q-item-section>
                          <q-item-label class="q-mt-md q-mb-md">
                            {{ form_item.label }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-item-section>
                          <div v-if="form_item.type==='Datetime'" class="flex py-4 px-4">
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='date'" type="date"/>
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='datetime'" type="datetime-local"/>
                            <input  v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='time'" type="time"/>
                          </div>
                          <div v-if="form_item.type==='Text'" class="flex  py-4 px-4">
                                        <textarea
                                            v-model="item.answers"
                                            :rows="JSON.parse(form_item.options).lines"
                                            :minlength="JSON.parse(form_item.options).minLength"
                                            :maxlength="JSON.parse(form_item.options).maxLength"
                                        />
                          </div>
                          <div v-if="form_item.type==='Signature'" class="flex py-4 px-4">
                            <input  type="hidden" v-model="item.answers"/>
                            <SignatureCard :model-value="item.file"
                                           @update:extension="extension => handleExtension(extension, form_item)"
                                           @update:files="files => handleFiles(files, form_item)">
                            </SignatureCard>
                          </div>
                          <div v-if="form_item.type==='Number'" class="flex  py-4 px-4">
                            <input
                                v-model="item.answers" type="number"
                                :min="JSON.parse(form_item.options).Minimum"
                                :max="JSON.parse(form_item.options).Maximum"
                            />
                          </div>
                          <div v-if="form_item.type==='Choices'" class="flex  py-4 px-4">
                            <select   v-model="item.answers" :multiple="JSON.parse(form_item.options).multiChoice" class="rounded-lg mt-2 mb-2">
                              <option selected>
                                -- Select --
                              </option>
                              <option v-for="choice in JSON.parse(form_item.options).selectable_options" :key="choice" :value="choice">
                                {{ choice }}
                              </option>
                            </select>
                          </div>
                          <div v-if="form_item.type==='Boolean'" class="flex py-4 px-4">
                            <select  v-model="item.answers" class="rounded-lg mt-2 mb-2">
                              <option selected>
                                -- Select --
                              </option>
                              <option :value="JSON.parse(form_item.options).falseOption">
                                {{ JSON.parse(form_item.options).falseOption }}
                              </option>
                              <option :value="JSON.parse(form_item.options).trueOption">
                                {{ JSON.parse(form_item.options).trueOption }}
                              </option>
                            </select>
                          </div>
                          <div class="q-mt-md q-mb-md" v-if="form_item.type==='Boolean' && JSON.parse(form_item.options).negativeActionValues.hasPhotos">
                            <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                              <DropzoneComponent
                                  @update:extension="extension => handleExtension(extension, form_item)"
                                  @update:files="files => handleFiles(files, form_item)"
                                  :type="(form_item.options).fileType">
                              </DropzoneComponent>
                            </div>
                            <div class="w-1/3" v-if="form_item.file">
                              <img
                                  :src="createBlob(form_item)"
                                  style="height: 140px; max-width: 150px"
                              >
                            </div>
                          </div>
                          <div v-if="form_item.type==='File'" class="flex  py-4 px-4">
                            <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                              <DropzoneComponent
                                  @update:extension="extension => handleExtension(extension, form_item)"
                                  @update:files="files => handleFiles(files, form_item)"
                                  :type="(form_item.options).fileType">
                              </DropzoneComponent>
                            </div>
                            <div class="w-1/3" v-if="form_item.file">
                              <img
                                  :src="createBlob(form_item)"
                                  style="height: 140px; max-width: 150px"
                              >
                            </div>
                          </div>
                        </q-item-section>
                      </q-item>
                      <q-item>
                        <q-btn @click="storeAnswer(form_item)">Submit</q-btn>
                      </q-item>
                    </div>
                  </div>
                </div>
              </div>
              <q-item class="mt-4 mb-4">
                  <q-btn @click="this.$emit('closeModal')">Close</q-btn>
              </q-item>
            </q-card-section>
          </q-card>
      </q-page>
    </q-page-container>
  </q-dialog>
</template>

<script>
import SignatureCard from "@/components/SignatureCard";
import axios  from "axios";
import {Notify} from "quasar";
import DropzoneComponent from "@/components/DropzoneComponent.vue";
export default {
  name: "ChecklistPage",
  props: [ 'form','showModal','task'],
  emits: ['closeModal','reloadForm'],
  components: {
    DropzoneComponent,
    SignatureCard
  },
  computed:{
    url(){
      return this.$store.getters.url;
    }
  },
  watch:{
   the_form:{
     handler(){
       this.storeFormWithoutClosing();
     },
     deep: true
   }
  },
  methods:{
    createBlob(item){
      return URL.createObjectURL(item.file);
    },
    handleFiles(files,item){
      item.file = files;
      console.log("Files: ");
      console.log(item);
      if(item.type==='File' || item.type==='Signature'){
          item.form_answers[0].answers = JSON.parse(item.options).fileName;
      }
      console.log("Files: ");
    },
    handleExtension(extension,item){
      console.log("Extension: ", extension)
      item.file_extension = extension;
    },
    storeForm(){
      let formData = new FormData();
      this.the_form.form_items.forEach((item, index) => {
        if (item.file) {
          let blob = new Blob([item.file], { type: 'image/png' });
          let file_name = JSON.parse(item.options).fileName + '.' + item.file_extension;
          if(item.type==='Boolean'){
            file_name = item.description+'.' + item.file_extension;
          }
          formData.append(`form_items[${index}][id]`, item.id);
          formData.append(`form_items[${index}][file]`,blob , file_name);
          formData.append(`form_items[${index}][form_answers]` , JSON.stringify(item.form_answers));
        }else {
          formData.append(`form_items[${index}][id]`, item.id);
          formData.append(`form_items[${index}][form_answers]`, JSON.stringify(item.form_answers));
        }
      });
      let config = {
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(this.url+'/api/api-form-filled-store',formData,config)
          .then((res) => {
            if(res.data.type==='success'){
              this.$emit('closeModal');
              Notify.create({
                message: res.data.message,
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done'
              });
            }else{
              Notify.create({
                message: res.data.message,
                color: 'red-4',
                textColor: 'white',
                icon: 'warning'
              });
            }
          }).catch((e) => {
        console.log(e);
      });
    },
    storeAnswer(item){
      let formData = new FormData();
      if (item.file) {
        let blob = new Blob([item.file], { type: 'image/png' });
        let file_name = JSON.parse(item.options).fileName + '.' + item.file_extension;
        if(item.type==='Boolean'){
          file_name = item.description+'.' + item.file_extension;
        }
        formData.append(`form_item_id`, item.id);
        formData.append(`form_answer_id`, item.form_answers[0].id);
        formData.append(`form_item_file`,blob , file_name);
        formData.append(`form_answers` , JSON.stringify(item.form_answers[0].answers));
      }else {
        formData.append(`form_answer_id`, item.form_answers[0].id);
        formData.append(`form_item_id`, item.id);
        formData.append(`form_answers`, JSON.stringify(item.form_answers[0].answers));
      }
      let config = {
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(this.url+'/api/api-form-answer-store',formData,config)
          .then((res) => {
            if(res.data.type==='success'){
              item.form_answers[0].submitted =1;
              Notify.create({
                message: res.data.message,
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done'
              });
              this.$emit('reloadForm');
            }else{
              Notify.create({
                message: res.data.message,
                color: 'red-4',
                textColor: 'white',
                icon: 'warning'
              });
            }
          }).catch((e) => {
        console.log(e);
      });
    },
    storeFormWithoutClosing() {
    }
  },
  data(){
    return {
        currentPage : 1,
        the_form : this.form,
        modal_shown: this.showModal
    }
  },
  mounted() {
    console.log(this.the_form);
  }
}
</script>

<style scoped>

</style>