<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
      <div :key="render_table">
        <div v-if="this.returned_cases" class="text-h3">You have {{returned_cases.length}}</div>
        <div v-for="(reported_case,index) in returned_cases" :key="index">
          <q-list bordered class="clickable">
            <q-item :to="'/case/'+reported_case.id">
              <q-item-section class="text-h5" :id="index">{{reported_case.id}} - {{reported_case.description}}</q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
            </q-item>
            <q-item :to="'/case/'+reported_case.id">
              <q-item-section>Reporter: {{reported_case.name}} {{reported_case.surname}}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} : {{reported_case.reference}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} Status: {{reported_case.status.name}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} Stage: {{reported_case.stage.name}}
              </q-item-section>
            </q-item>
            <q-item>
              {{this.docket_description}} Categories :
            </q-item>
            <q-item>
              <q-item-section>
                <q-chip v-for="category in reported_case.docket_categories" :key="category" color="primary" text-color="white">
                  {{ category.name}}
                </q-chip>
              </q-item-section>
            </q-item>
            <q-item  :to="'/case/'+reported_case.id">
              <q-item-section :id="index">Log date: {{reported_case.created_at}}</q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import {useQuasar} from "quasar";

export default {
  name: 'OpenCasesPage',
  setup(){
    return {
    }
  },
  data(){
    return {
      returned_cases : [],
      render_table : null,
      current_user : null,
      users : null

    }
  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    },
    level_ones : function(){
      return this.$store.getters.level_ones;
    },
    one_description: function(){
      return this.$store.getters.one_description;
    },
    current_level_one: function(){
      let one = this.$store.getters.current_level_one;
      return one;
    },
    level_twos : function(){
      return this.$store.getters.level_twos;
    },
    two_description: function(){
      return this.$store.getters.two_description;
    },
    current_level_two: function(){
      let two = this.$store.getters.current_level_two;
      return two;
    },
    level_threes : function(){
      return this.$store.getters.level_threes;
    },
    three_description: function(){
      return this.$store.getters.three_description;
    },
    current_level_three: function(){
      let three = this.$store.getters.current_level_three;
      return three;
    },
    level_fours : function(){
      return this.$store.getters.level_fours;
    },
    four_description: function(){
      return this.$store.getters.four_description;
    },
    current_level_four: function(){
      let four = this.$store.getters.current_level_four;
      return four;
    },
    level_fives : function(){
      return this.$store.getters.level_fives;
    },
    current_level_five: function(){
      let five = this.$store.getters.current_level_five;
      return five;
    },
    five_description: function(){
      return this.$store.getters.five_description;
    },
    token: function () {
      return this.$store.getters.token;
    },
    docket_description: function(){
      return this.$store.getters.docket_description;
    }
  },
  mounted(){
    this.syncData();
  },
  methods : {
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users',{
        headers :{
          Authorization : 'Bearer '+this.token
        },
        params : {
          owner_type : 'CreatedByMe',
          status_string : 'Open'
        }
      })
          .then((res) => {
            this.users = res.data.data;
            let user_id = JSON.parse(localStorage.getItem('user_id'));
            this.users.forEach((user) => {
              if(user.id === user_id){
                this.current_user = user.name;
              }
            });
          })
          .catch(() => {
          }).finally(() => {
      });
    },
    async syncData(){
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-dockets',{
        headers:{
          Authorization : 'Bearer '+this.token
        },
        params:{
          owner_type : 'CreatedByMe'
        }
      })
          .then((res) => {
            // eslint-disable-next-line no-unreachable
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: this.docket_description +'s Retrieved !! '
            })
            this.returned_cases = res.data;
            this.render_table = true;
            console.log(this.returned_cases);
          })
          .catch((error) => {
            console.log(error)
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
